<script>
import TransportaionProductMixins from './TransportationProductMixins.vue';

export default {
  name: 'ProductQuestionsMixins',
  mixins: [TransportaionProductMixins],
  methods: {
    loadQuestions() {
      this.$store.dispatch(`${this.storeModuleName}/loadQuestions`, {
        transportationId: this.transportationId,
      });
    },
  },
  computed: {
    transportationQuestions() {
      return this.$store.state[this.storeModuleName].questions;
    },
  },
};
</script>
