<script>
import TransportaionProductMixins from './TransportationProductMixins.vue';

export default {
  name: 'TransportationProductOrderInformationMixins',
  mixins: [TransportaionProductMixins],
  methods: {
    answerQuestions(answers) {
      this.$store.dispatch(`${this.storeModuleName}/answerQuestions`, {
        answers: answers,
      });
    },
    setTravelers(travelers) {
      this.$store.dispatch(`${this.storeModuleName}/setTravelers`, travelers);
    },
  },
  computed: {
    orderInformation() {
      return this.$store.state[this.storeModuleName].orderInformation;
    },
  },
};
</script>
