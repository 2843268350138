<template>
  <v-container class="pa-0">
    <v-row no-gutters>
      <v-col cols="12">
        <v-skeleton-loader
          :loading="loading"
          height="94"
          type="list-item-three-line"
        >
          <template v-if="!hasError">
            <v-form data-cy="contact-info-form" ref="form">
              <ProductQuestionsForm
                v-for="(item, index) in transportationQuestions.value"
                :key="index"
                :index="index"
                :questions="item.questions"
                :title="item.title"
                @createTraveler="onCreateTraveler"
                @formChange="onFormChange"
                @is-valid="setEnableButton(item.title, $event)"
              />
            </v-form>
            <v-row
              v-if="$vuetify.breakpoint.mdAndDown"
              class="px-4 traveler-return-wrapper"
            >
              <v-btn
                class="primary size20-weight700 traveler-button"
                justify="end"
                depressed
                data-cy="go-to-payment"
                :loading="isLoading"
                :disabled="!buttonEnabled"
                @click="validate"
              >
                {{ $t('proceed_payment') }}
              </v-btn>
            </v-row>
          </template>

          <template v-else>
            <div class="pa-5">{{ transportationQuestions.error }}</div>
          </template>
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TransportationProductDetailsMixins from '../../mixins/TransportationProductDetailsMixins.vue';
import TransportationProductQuestionsMixins from '../../mixins/TransportationProductQuestionsMixins.vue';
import TransportationProductOrderInformationMixins from '../../mixins/TransportationProductOrderInformationMixins.vue';
import ProductQuestionsForm from '@/modules/product/features/QuestionsForm/QuestionsForm.vue';
export default {
  name: 'TransportationProductQuestionsContainer',
  components: {
    ProductQuestionsForm,
  },

  mixins: [
    TransportationProductDetailsMixins,
    TransportationProductQuestionsMixins,
    TransportationProductOrderInformationMixins,
  ],

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      travelers: [],
      sectionValid: {},
      buttonEnabled: false,
    };
  },

  created() {
    this.loadQuestions();
  },

  computed: {
    answers() {
      return { ...this.orderInformation.answers };
    },

    loading() {
      return this.transportationQuestions.isLoading;
    },

    hasModel() {
      return (
        this.transportationQuestions.value !== null &&
        this.transportationQuestions.value !== undefined
      );
    },

    hasError() {
      return (
        this.transportationQuestions.error !== null &&
        this.transportationQuestions.error !== undefined &&
        !this.hasModel
      );
    },
  },

  methods: {
    onFormChange(val) {
      this.answers[val.id] = val.value;
      this.answerQuestions(this.answers);
    },

    validate() {
      if (this.$refs.form.validate()) {
        this.setTravelers(this.travelers);
        this.$emit('submitForm');
      }
    },

    goToDetails() {
      this.$router.push({
        name: 'transportation-details',
      });
    },

    onCreateTraveler(traveler) {
      if (this.travelers.length !== 0) {
        let newTraveler = true;

        this.travelers.map((item) => {
          if (item.id === traveler.id) {
            newTraveler = false;
            return traveler;
          }
        });

        if (newTraveler) this.travelers.push(traveler);
      } else {
        this.travelers.push(traveler);
      }
    },

    setEnableButton(section, enable) {
      this.sectionValid[section] = enable;
      this.buttonEnabled = Object.values(this.sectionValid).every(
        (item) => item
      );
      this.$emit('is-valid', this.buttonEnabled);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.traveler {
  &-return {
    &-wrapper {
      @media (max-width: map-get($grid-breakpoints, 'md')) {
        padding: 0 12px !important;
      }

      @media (max-width: map-get($grid-breakpoints, 'sm')) {
        & > button {
          width: 100%;
        }
      }
    }

    &-link {
      color: var(--v-grey9-base);
      text-decoration: none;
    }
  }

  &-button {
    padding: 12px 20px !important;
    border-radius: 8px;
    height: 54px !important;
    letter-spacing: 0;
  }
}
</style>
