import OrderCreation from './OrderCreation.js';

export default class TransportationOrder extends OrderCreation {
  constructor({ amount, answers, travelerId, productId }) {
    super();
    const mappedAnswers = this.mapAnswers(answers);
    const validatedAmount = this.validateAmount(amount);

    this.init({
      travelerId,
      amount: validatedAmount,
      products: [
        {
          id: productId,
          answers: mappedAnswers,
        },
      ],
    });
  }

  validateAmount(
    { value = null, currency = null } = { value: null, currency: null }
  ) {
    if (value === null) throw new Error('Amount value cannot be null');
    if (currency === null) throw new Error('Amount value cannot be null');
    return {
      value,
      currency,
    };
  }

  mapAnswers(answers) {
    let answerList = [];

    if (answers != null) {
      const answerEntries = Object.entries(answers);
      for (const [key, value] of answerEntries) {
        answerList.push({
          questionId: key,
          value: value,
        });
      }
    }
    return answerList;
  }
}
