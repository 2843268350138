<template>
  <v-container class="pa-0">
    <v-row>
      <v-col justify-self="center">
        <TransportationProductQuestionsContainer
          ref="questionsContainer"
          :isLoading="isLoading"
          @submitForm="onSubmitProceedToPayment"
          @is-valid="$emit('is-valid', $event)"
        />

        <template v-if="error">
          <v-alert type="error" transition="bounceInRight" class="mt-4">
            {{ error }}
          </v-alert>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TransportationProductOrderInformationMixins from '@/modules/product/transportation/mixins/TransportationProductOrderInformationMixins';
import TransportationProductQuestionsContainer from './features/Contact/TransportationProductQuestionsContainer';
import TransportationModel from '@/modules/product/models/TransportationOrder.js';
import TransportationProductDetailsMixins from './mixins/TransportationProductDetailsMixins';
import TransportationProductMixins from '@/modules/product/transportation/mixins/TransportationProductMixins';
import ProductOrderMixins from '@/modules/product/mixins/ProductOrderMixins';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import ProductCheckoutMixins from '@/modules/product/mixins/ProductCheckoutMixins';
import ProductNavigationMixins from '@/modules/product/mixins/ProductNavigationMixins';
import TravelerMixins from '@/modules/common/mixins/TravelerMixins';

export default {
  name: 'contact-page',
  mixins: [
    TransportationProductOrderInformationMixins,
    TransportationProductDetailsMixins,
    TransportationProductMixins,
    ProductCheckoutMixins,
    ProductOrderMixins,
    ProductNavigationMixins,
    GoogleTagManagerMixins,
    TravelerMixins,
  ],
  components: {
    TransportationProductQuestionsContainer,
  },

  data() {
    return {
      isLoading: false,
      error: null,
    };
  },

  methods: {
    onClickProceedToPayment() {
      this.$refs.questionsContainer.validate();
    },
    onSubmitProceedToPayment() {
      this.isLoading = true;
      this.pushCheckoutAnalytics(this.transportationId, 2, 'transportation');
      this.transformAndCreateOrder();
    },

    transformAndCreateOrder(travelerId = this.travelerId) {
      let transportOrderModel = null;
      const { priceStartingAt } = this.transportationDetailsModel;
      try {
        transportOrderModel = new TransportationModel({
          travelerId,
          amount: {
            value: priceStartingAt.value,
            currency: priceStartingAt.baseCurrency,
          },
          answers: this.orderInformation.answers,
          productId: this.transportationId,
        });
      } catch (error) {
        this.error = error.toString();
        this.isLoading = false;
      } finally {
        if (transportOrderModel) {
          this.setOrderProductId(this.transportationId);
          this.createOrder(transportOrderModel, travelerId);
        }
      }
    },

    proceedToPayment(localOrderId) {
      this.setRedirectPathFromOrderConfirmationPage({
        redirectPathFromConfirmation: {
          name: 'transportationHome',
        },
      });
      this.$router.push({
        name: 'transportation-payment',
        params: {
          localOrderId,
        },
      });
    },
  },

  watch: {
    orderCreation(val) {
      if (!!val && !!val.error) {
        this.isLoading = false;
        this.error = val.error;
      } else if (!!val && !!val.response) {
        this.isLoading = false;
        this.$emit('onLoading', this.isLoading);
        this.proceedToPayment(this.localProductOrderId);
      }
    },
    isLoading(newValue) {
      this.$emit('onLoading', newValue);
    },
  },
};
</script>

<style lang="scss" scoped></style>
